:root {
  --footerBg: #121d5f;
  --fontColor: #d5d5d5;
}

/* footer section code  */

.footer-section-main {
  background-color: var(--footerBg);
  margin-top: 2em !important;
  z-index: 23000;
  padding: 2em 1em 0.5em;
}

.footer-section {
}
.social-media {
  margin-top: 0.5em;
}
.text-with-icon {
  display: flex;
  word-break: break-all;
}
.text-with-icon .css-i4bv87-MuiSvgIcon-root {
  width: 20px;
}

.social-media a img {
  margin-right: 10px;
}

.copy-title {
  text-align: center;
  color: var(--fontColor);
}

.footer-logo {
  background-color: var(--fontColor);
  border-radius: 5px;
  padding: 0.3em;
}

.contact-info {
  display: flex;
  justify-content: flex-end;
}
.about-us-sec {
  display: flex;
  justify-content: flex-end;
}
.social-media-sec {
  display: flex;
  justify-content: flex-end;
}
.contact-info .MuiTypography-root {
  color: var(--fontColor);
}

.about-section .MuiTypography-root {
  color: var(--fontColor);
}
.app-store .apple-img {
  margin-top: 0.5em;
}
.header-title {
  color: var(--fontColor);
  border-bottom: 2px solid var(--fontColor);
  display: inline-block;
}
.logo-content {
  max-width: 400px;
}
.logo-content .MuiTypography-root {
  color: var(--fontColor);
}
.header-title.css-ag7rrr-MuiTypography-root {
  margin-bottom: 0.5em !important;
}
.about-us-sec .abt-link {
  text-decoration: none;
}
.about-us-sec .abt-link .MuiTypography-root {
  transition: color 0.2s linear;
}
.about-us-sec .abt-link:hover .MuiTypography-root {
  color: #d5088d;
  border-bottom: 2px solid #d5088d;
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .contact-info {
    padding-left: 3.5em;
  }
  .logo-content {
    max-width: 350px;
  }
  .footer-logo {
    width: 190px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-logo {
    width: 180px;
  }
}

@media screen and (max-width: 950px) {
  .about-us-sec {
    justify-content: unset;
  }
  .contact-info {
    justify-content: unset;
  }
  .social-media-sec {
    justify-content: unset;
  }
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.insta {
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.twitter {
}

.payment-copy-section {
}

.payment-section {
  text-align: center;
  color: var(--fontColor);
}
.payment-section img {
  margin: 0 0.3em;
}
