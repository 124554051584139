.cart-count {
    background-color: #009e7f;
    position: fixed;
    right: 0%;
    top: 50%;
    z-index: 100;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 90px;
  }
  .item-count {
    display: flex;
    justify-content: center;
    margin: 0.9em 0;
  }
  .item-count .item-icon {
    color: #fff;
    font-size: 18px;
    margin: 0 3px 0 0;
  }
  .item-count .count-number {
    color: #fff;
    font-weight: 500;
    font-size: 0.9em;
    margin: 0 0 0 3px;
  }
  .money-count {
    background: #fff;
    margin: 0.5em;
    padding: 0.3em 0.6em;
    border-radius: 5px;
    color: #009e7f;
    font-size: 0.9em;
    font-weight: 550;
    text-align: center;
  }
  