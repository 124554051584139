.modal-grid-inner-main {
  .modal-content-right {
    margin: 1em 2em;
    .modal-title-content {
      .product-name {
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: $modal-text;
        line-height: 2;
        display: flex;
      }
      .product-size {
        font-family: Lato, sans-serif;
        font-size: 16px;
        line-height: 2;
        font-weight: 600;
        color: rgb(0, 0, 255);
      }
    }
    .add-to-cart-sec {
      .price-sec {
        margin: 1.5em 0;
        display: flex;
        align-items: center;
        .discount-price {
          font-family: Lato, sans-serif;
          font-size: 21px;
          font-weight: 700;
          color: $logo-color1;
        }
        .actual-price {
          font-family: Lato, sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: rgb(255, 0, 0);
          font-style: italic;
          text-decoration: line-through;
          padding-left: 12px;
        }
      }
    }
    .description-sec {
      margin-top: 1.5em;
      .des-text {
        text-align: justify;
        font-size: 0.85em;
      }
    }
    @include muiSm {
      margin: 1em 1em;
    }
  }
  .sm-screen-btn {
    position: absolute;
    align-items: right;
    justify-content: center;
    top: 15px;
    right: 15px;
    z-index: 10;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: none;
  }
  .carousel-root.cw-carousel {
    .carousel.carousel-slider {
      .control-dots {
      }
      .slider-wrapper.axis-horizontal {
        .slider {
          @include muiMd {
            .slide {
            }
          }

          .selected.previous {
            .slide {
              img {
              }
            }
          }
        }
      }
    }
    .carousel {
      .thumbs-wrapper.axis-vertical {
        margin: 0;
        .thumbs {
          .thumb {
            width: 60px !important;
            &.selected {
              border-color: $logo-color1;
            }
          }
        }
        @include muiSmToMd {
          .thumbs {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
        }
        @include muiSm {
          .thumbs {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
        }

        @include below450 {
          .thumbs {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
        }
        .thumb.selected {
          img {
          }
        }
      }
    }
  }
}
