.privacy-policy-main {
}
.privacy-policy-content {
}
.privacy-policy-content {
  margin: 3em 1em;
}
.privacy-policy-content .MuiTypography-root.MuiTypography-body2 {
  margin: 1.5em 0;
}
