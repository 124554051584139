.slide-container {
  width: 100%;
  margin: auto;
}
@media screen and (max-width: 599px) {
  .slide-container.custom-style {
    margin-top: 2.9em;
  }
}
/*   
  h3 {
    text-align: center; }
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: auto;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  @media (max-width: 999px){
          .each-slide > div {
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            height: 170px;
          }
  }

  @media (max-width: 850px){
      .each-slide > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 155px;
      }
  }

  @media (max-width: 750px){
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 140px;
    }
  }

  @media (max-width: 650px){
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 125px;
    }
  }

  @media (min-width: 1650px) and (max-width: 2000px) {
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 460px;
    }
  }

  @media (min-width: 1480px) and (max-width: 1650px) {
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 400px;
    }
  }

  @media (min-width: 1380px) and (max-width: 1480px) {
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 400px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1379px) {
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 300px;
    }
  }

  @media (min-width: 1000px) and (max-width: 1199px) {
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 260px;
    }
  } */