.product_card_main_sec {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: left;

  .product-single-card {
    margin: 0.5em 0.3em;
    flex-basis: 43.2%;
    padding: 0.3em;
    position: relative;
    box-shadow: none;

    .product-card-img {
      object-fit: contain;
      height: 190px;
    }

    .card-content {
      width: 95%;
      margin: 1em auto 0;

      .common-card-title {
        font-size: 0.9em;
        letter-spacing: 0.3px;
        color: #333;
      }

      .product-size {
        font-size: 0.8em;
        letter-spacing: 0.3px;
        color: #009e7f;
        margin-top: 0.8em;
      }

      .product-price {
        font-size: 1em;
        letter-spacing: 0.3px;

        .sales-price {
          color: #009e7f;
          font-size: 0.9em;
          font-weight: 500;
        }

        .origin-price {
          color: #d5088d;
          font-weight: 500;
          margin-left: 1.5em;
          text-decoration: line-through;
        }
      }

      .common-card-title {
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        white-space: normal;
      }
    }

    .add-to-cart-btn.MuiButtonBase-root {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $add-to-cart-btn-text-color;
      margin: 1em auto;
      padding: 3px 10px;
      text-transform: capitalize;
      font-size: 0.9em;
      letter-spacing: 0.5px;
      font-weight: 500;
      border-color: $add-to-cart-btn-border-color;
      transition: background-color 0.2 linear, color 0.2s linear;

      .MuiSvgIcon-root {
        font-size: 1.3em;
        margin-right: 0.5em;
      }

      &:hover {
        background-color: #009e7f;
        color: #fff;
        border-color: #009e7f;
      }
    }

    .add-to-cart-btn-after {
      width: 95%;
      background-color: $logo-color1;
      margin: 1em auto;
      margin-bottom: 0.5em auto;
      border-radius: 3px;
      border-color: $add-to-cart-btn-border-color;

      .add-remove {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .MuiButtonBase-root {
          padding: 3px 5px;
        }

        .add-icon {
          padding-right: 2px;
          color: $light-white1;
          border-left: 1px solid;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .remove-icon {
          color: $light-white1;
          border-right: 1px solid;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .item-count-text {
          color: $white;
          text-transform: capitalize;
          font-size: 0.9em;
          letter-spacing: 0.5px;
          font-weight: 500;
        }
      }
    }

    .out-of-stock-btn.MuiButtonBase-root {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $out-of-stock-btn-border-color;
      margin: 1em auto;
      padding: 3px 10px;
      text-transform: capitalize;
      font-size: 0.9em;
      letter-spacing: 0.5px;
      font-weight: 500;
      border-color: $out-of-stock-btn-border-color;
      transition: background-color 0.2 linear, color 0.2s linear;

      .MuiSvgIcon-root {
        font-size: 1.3em;
        margin-right: 0.5em;
      }

      &:hover {
        background-color: $out-of-stock-btn-border-color;
        color: #fff;
        border-color: #009e7f;
      }
    }

    /* .add-to-cart-btn.MuiButtonBase-root.after-adding {
      background-color: #009e7f;
      color: #fff;
      border-color: #009e7f;
    } */
    @media screen and (max-width: 599px) {
      .product-card-img {
        object-fit: cover;
        width: 90%;
        height: 55%;
        margin: 0 auto;
      }

      .add-to-cart-btn.MuiButtonBase-root {
        padding: 0 10px;
      }

      .add-to-cart-btn .add-remove {
        padding: 0px 0px;
      }
    }
  }

  .discount-percent {
    background-color: #006350;
    position: absolute;
    top: 7px;
    left: 7px;
    color: #fff;
    //width: 70px;
    font-weight: 400;
    border-radius: 5px;
  }

  @media only screen and (min-width: 600px) and (max-width: 899px) {
    .product-single-card {
      margin: 0.5em 0.3em;
      flex-basis: 29.8%;
      padding: 0.3em;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .product-single-card {
      margin: 0.5em 0.3em;
      flex-basis: 22.5%;
      padding: 0.3em;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .product-single-card {
      margin: 0.5em 0.5em;
      flex-basis: 21.82%;
      padding: 0.5em;
    }
  }

  @media only screen and (min-width: 1501px) and (max-width: 1700px) {
    .product-single-card {
      margin: 0.5em 0.8em;
      flex-basis: 20.5%;
      padding: 0.8em;
    }
  }

  @media only screen and (min-width: 1701px) and (max-width: 1800px) {
    .product-single-card {
      margin: 0.5em 0.8em;
      flex-basis: 16.1%;
      padding: 0.8em;
    }
  }

  @media only screen and (min-width: 1801px) and (max-width: 2500px) {
    .product-single-card {
      margin: 0.3em 0.3em;
      flex-basis: 15%;
      padding: 0.4em;
    }
  }

  @media only screen and (min-width: 2500px) {
    .product-single-card {
      margin: 0.5em 0.8em;
      flex-basis: 14%;
      padding: 0.8em;
    }
  }

  @media only screen and (max-width: 599px) {
    .product_card_main_sec {
      justify-content: center;
    }
  }

  @include muiMd {
    justify-content: center;
  }

  @include muiSm {
    justify-content: space-between;

    .product-single-card {
      flex-basis: 45.2%;
    }
  }

  @include below450 {
    .product-single-card {
      flex-basis: 43.2%;
    }
  }
}