:root {
  --blue: #009e7f;
}

.checkout-sec {
  top: 50%;
  left: 50%;
  max-width: 800px;
  background-color: #fff;
  box-shadow: none;
  padding: 32px 32px 32px 32px;
  margin: 2em 0;
  border-radius: 5px;
}

.checkout-info-box {
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0px 1px 3px 0px #0000002e;
  position: relative;
}

.user-title-sec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.user-title-sec .edit-user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #d35757;
  padding: 0.3em;
  border-radius: 5px;
  cursor: pointer;
}

.edit-user-info .MuiSvgIcon-root {
  fill: #fff;
  margin-left: 0.5em;
  font-size: 1em;
}

.checkout-info-box .user-info-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.6em;
}

.checkout-info-box .user-info-title span {
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  margin-left: 1em;
}

.user-info-details p {
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
}

.user-info-title .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  fill: var(--blue);
}

.user-info-box .input-common {
  margin: 1em 0;
}

.general-notice.css-ahj2mt-MuiTypography-root {
  margin: 0.1em 0 2em;
  border-radius: 5px;
  padding: 1.5em;
  background: #009e7f;
  line-height: 25px;
  color: #fff;
}

.user-info-box .user-info-title {
  color: var(--blue);
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  letter-spacing: 1px;
}

.user-address {
  margin: 1em 0 1em;
}

.total-amount-sec {
  margin: 1em 0;
}

.total-payable-amount.MuiTypography-root {
  font-size: 1.2em;
  font-weight: 500;
  font-weight: bold;
}

.payment-sec {
  margin: 1em 0;
}

.checkout-info-box .checkout-cw-btn {
  background-color: var(--blue);
}

.checkout-info-box .checkout-cw-btn:hover {
  background-color: var(--blue);
}

.payment-icon {
  display: flex;
}

.payment-icon .common-img {
  height: 25px;
  margin: auto 0;
}

.payment-icon .common-img .visa {
  height: 25px;
}

.payment-icon .common-img.nagad {
  height: 20px;
}

.payment-icon .common-img.cash {
  height: 20px;
}

/* total payable section  */
.sub-total-sec {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 5px;
}

.sub-total-sec .sub-total-text {
  color: #0d1136;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: right;
}


.total-sec {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.total-sec .total-sec-text {
  font-size: 1.3rem;
  font-weight: 700;
  color: #b4138e;
}

@media screen and (max-width:599px) {
  .sub-total-sec .sub-total-text {
    font-size: .9rem;
    
  }

  .total-sec .total-sec-text {
    font-size: 1rem;
   
  }
}

@media screen and (max-width: 1700px) and (min-width: 901px) {
  .checkout-sec {
    left: 55%;
    margin: 2em 0;
    padding: 20px;
  }
}

@media screen and (max-width: 900px) {
  .checkout-sec {
    box-shadow: none;
    margin: 2em 0;
    width: 70%;
    padding: 20px;
  }

  .login-info-box {}
}

@media screen and (max-width: 600px) {
  .checkout-sec {
    box-shadow: none;
    width: 80%;
    margin: 2em 0 0;
  }

  .login-info-box {}
}

@media screen and (max-width: 450px) {
  .checkout-sec {
    box-shadow: none;
    width: 80%;
    margin: 2em 0 0;
  }

  .checkout-info-box .checkout-cw-btn {
    position: fixed;
    bottom: 5px;
    width: 88%;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-logo {
    width: 200px;
  }
}