.modal-add-to-cart {
  .card-btn-box {
    display: flex;

    .card-btn-sec {
      display: flex;
      background: #edebeb;
      align-items: center;
      justify-content: center;
      margin: 1px 5px;
      border-radius: 0px;
      cursor: pointer;
      border: 1px solid;
      border-color: $logo-color1;

      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        width: 1.6em;
        height: 1.6em;
        box-shadow: none;
        background: $logo-color1;
        color: #fff;
        font-size: 1.5em;
        font-weight: bold;
        padding: 2.5px;
        cursor: pointer;
      }

      .card-add-btn.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        border-top-right-radius: 0em;
        border-bottom-right-radius: 0em;
      }

      .card-remove-btn.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        border-top-left-radius: 0em;
        border-bottom-left-radius: 0em;
      }

      .card-add-btn {
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-size: 22px;
        color: gray;
      }

      .card-input-btn {
        width: 128px;
        border: none;
        outline: none;
        background: none;
        font-size: 24px;
        font-weight: bold;
        color: $modal-text;
        text-align: center;
      }

      input[type='number']::-webkit-inner-spin-button,
      .card-btn-sec input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .card-remove-btn {
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-size: 22px;
        color: gray;
      }
    }
  }

  .before-add-btn {
    &.MuiButtonBase-root {
      background-color: #fff;
      border-radius: 19px;
      border: 1px solid;
      border-color: #80808054;
      padding: 2px 3px;
      color: $logo-color1;
      font-size: 20px;
      font-weight: 600;
      width: 270px;
      margin-left: 4px;
      text-transform: capitalize;
      transition: background-color 0.2s linear, color 0.2s linear;

      &:hover {
        background-color: $logo-color1;
        color: #fff;
      }

      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        width: 1.5em;
        height: 1.5em;
        box-shadow: none;
        background: #ffffff00;
        color: $logo-color1;
        font-size: 1.3em;
        font-weight: bold;
        padding: 0 0.3em 0 0;
        transition: color 0.2s linear;
      }

      &:hover {
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
          color: #fff;
        }
      }
    }
  }
}