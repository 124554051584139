:root {
  --blue: #009e7f;
}

.login-register-sec {
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
  border-radius: 5px;
  max-height: 80%;
  overflow-y: auto;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1500px) {
  .login-register-sec {
    top: 50%;
  }
}

.login-info-box {
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0px 1px 3px 0px #0000002e;
}

.login-info-box .otp-input {
  margin: 1em 0 2em;
}
.login-info-box .otp-sent-btn {
  background-color: var(--blue);
}
.login-info-box .otp-sent-btn:hover {
  background-color: var(--blue);
}

.user-info-box .input-common {
  margin: 1em 0;
}

.user-info-box .user-info-title {
  color: var(--blue);
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  letter-spacing: 1px;
}

.user-info-box .login-btn {
  background-color: var(--blue);
}
.user-info-box .login-btn:hover {
  background-color: var(--blue);
}

.login-info-box .otp-input input::-webkit-outer-spin-button,
.login-info-box .otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 900px) {
  .login-register-sec {
    box-shadow: none;
    width: 70%;
    padding: 20px;
    position: relative;
    transform: translate(0px, 0px);
    left: 0;
    top: 0;
    margin-top: 2em;
  }
  .login-info-box {
  }
}

@media screen and (max-width: 600px) {
  .login-register-sec {
    box-shadow: none;
    width: 80%;
  }
  .login-info-box {
  }
}