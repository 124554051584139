:root {
    --blue: #009e7f;
    --place-order: #fff;
}

.mobile-footer {
    display: none;
    align-items: center;
    justify-content: space-between;
    background: #009e7f;
    border-radius: 3px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.3em 0.5em;
    z-index: 10;
}

.home-btn-link .css-jcn4dz-MuiBadge-badge {
    top: 7px;
    background-color: #fff;
    color: #000;
    padding: 0 4px;
}

.cart-back-link-btn {
    color: #fff;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    display: flex;
}

.item-and-cost {
    background: #fff;
    color: #009e7f;
    padding: 0.2em 0.5em;
    border-radius: 16px;
    font-size: 0.9em !important;
    min-width: 60px;
    text-align: center;
}

.cart-back-link-btn span {
    font-size: 0.9em;
    font-weight: 500;
}

@media screen and (max-width: 580px) {
    .mobile-footer {
        display: flex;
    }
}

.mobile-footer .home-btn-link,
.cart-back-link {
    text-decoration: none;
}

.mobile-footer .place-order-common-btn {
    transition: color 0.2s linear;
    color: var(--place-order);
    border: 0;
    margin: 0.3em 0.2em;
    background: #006350;
    border-radius: 30px;
    padding: 3px 15px;
    font-size: 0.7em;
}

.mobile-footer .place-order-common-btn:hover {
    color: #ddd;
    background: #006350;
}

@media screen and (max-width: 400px) {
    .mobile-footer .place-order-common-btn {
        padding: 3px 9px;
    }
}