$logo-color1: #009e7f;
$logo-color2: #d5088d;
$logo-color3: #121d5f;
$white: #fff;
$new-black: #333;
$light-white: #bebebe;
$light-white1: #ddd;
$modal-text: #0d1136;
$out-of-stock-btn-border-color: #b71666;
$add-to-cart-btn-border-color: #009e7f;
$add-to-cart-btn-text-color: #009e7f;
$add-to-cart-btn-bag-color: #009e7f;
$mac: 4000px;
$desktop-max: 1200px;
$desktop-pro: 1199px;
$desktop: 992px;
$ipad-pro: 991px;
$ipad: 768px;
$mobile: 767px;
$mobile-mini: 540px;
$galaxy-pro: 500px;
$galaxy-micro: 539px;
$galaxy: 320px;
$muiSm: 599px;
$muiSmPlus: 600px;
$muiMd: 899px;
$below450: 450px;

@mixin muiSm {
  @media (max-width: #{$muiSm}) {
    @content;
  }
}

@mixin muiMd {
  @media (max-width: #{$muiMd}) {
    @content;
  }
}

@mixin below450 {
  @media (max-width: #{$below450}) {
    @content;
  }
}

@mixin muiSmToMd {
  @media (max-width: #{$muiMd}) and (min-width: #{$muiSmPlus}) {
    @content;
  }
}

@mixin ipad-pro {
  @media (max-width: #{$ipad-pro}) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: #{$ipad-pro}) and (min-width: #{$ipad}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) and (min-width: #{$mobile-mini}) {
    @content;
  }
}

@mixin galaxy {
  @media (max-width: #{$galaxy-micro}) and (min-width: #{$galaxy}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop-pro}) and (min-width: #{$desktop}) {
    @content;
  }
}

@mixin mac {
  @media (max-width: #{$mac}) and (min-width: #{$desktop-max}) {
    @content;
  }
}

@mixin galaxy-pro {
  @media (max-width: #{$galaxy-micro}) and (min-width: #{$galaxy-pro}) {
    @content;
  }
}