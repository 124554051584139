.category-main-page {
    margin-top: 0 !important;

    .banner-grid {
        padding-top: 10px;
    }

    .banner-section {
        background-repeat: no-repeat !important;
        background-size: 100% !important;
        height: 164px;
    }

    .card-sec {
        box-shadow: none !important;
        transition: transform 0.3s linear, box-shadow 0.3s linear !important;
        position: relative !important;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #05050567;
            transition: width 0.3s linear;
        }

        &:hover:after {
            width: 100%;
        }

        .cat-view-more-btn-text {
            color: rgb(241 241 241);
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: bold !important;
            font-size: 1.2em !important;
            z-index: 100;
            transform: translate(-50%, -50%);
            opacity: 0;
            text-align: center;
            transition: opacity 0.3s linear;

            &:hover {
                opacity: 1;
            }
        }
    }



}

@media screen and (max-width: 900px) {
    .banner-grid {
        padding-top: 10px;
    }
}