:root {
    --blue: #009e7f;
    --place-order: #fff;
  }
  .place-order-sec {
    display: none;
    justify-content: space-between;
    background: #009e7f;
    border-radius: 3px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.3em 0.5em;
    z-index: 10;
  }
  @media screen and (max-width: 580px) {
    .place-order-sec {
      display: flex;
    }
  }
  .place-order-sec .home-btn-link,
  .cart-back-link {
    text-decoration: none;
  }
  .place-order-sec .place-order-common-btn {
    transition: color 0.2s linear;
    color: var(--place-order);
    border: 0;
    margin: 0.3em 0.2em;
    background: #006350;
    border-radius: 30px;
    padding: 3px 15px;
    font-size: 0.7em;
  }
  .place-order-sec .place-order-common-btn:hover {
    color: #ddd;
    background: #006350;
  }
  
  @media screen and (max-width: 400px) {
    .place-order-sec .place-order-common-btn {
      padding: 3px 9px;
    }
  }  