.section {
  padding: 50px 0;
}

@media screen and (max-width: 580px) {
  .section {
    padding: 5px 0;
  }
}

.css-gepadz-MuiTypography-root.order-page-title {
  color: #b4138e;
  font-size: 2.5em;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.order-info-box {
  margin: 0.5em 0;
  border-radius: 10px;
  padding: 1.3em 1em;
  background: #fff;
  box-shadow: 2px 2px 1px 0px #ddd;
  transition: transform 0.2s linear, background-color 0.2s linear;
}

.order-info-box:hover {
  transform: translateX(8px);
  background-color: #121d5f03;
}

.order-info-box.active {
  outline: 1px solid #0000002b;
  background-color: #121d5f03;
  box-shadow: 1px 2px 1px 0px #0000007d;
}

.order-info-box .order-info {
  font-size: 0.9em !important;
  letter-spacing: 1px;
  word-spacing: 3px;
  font-size: 1em;
  letter-spacing: 1px;
  word-spacing: 3px;
}

.order-info .info-name {
  font-weight: 500;
  margin-right: 5px;
  font-weight: 500;
  margin-right: 5px;
  font-size: 1.1em !important;
}

.details-main-sec {
  background-color: #fff;
  padding: 1em 1em;
  border-radius: 10px;
  box-shadow: 2px 2px 1px 0px #ddd;
}

/* cart main content section cart-with-product*/

.product-img-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-img-sec .product-img {
  max-width: 90px;
  width: 100%;
}

@media screen and (max-width: 400px) {
  .product-img-sec img {
    width: 50px;
  }
}

.title-sec {
  padding-left: 1.5em;
}

.title-sec-inner {
  padding: 0.7em 0;
}

.title-sec-inner .product-title {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-weight: 600;
  color: #0d1136;
  margin-bottom: 0px;
  font-size: 0.95em;
  line-height: 1.2;
}

.title-sec-inner .product-price-sec {
  display: flex;
}

.product-price-sec .total-price {
  color: #0d1136;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2em;
}

.product-price-sec .unit-price {
  color: var(--blue);
  margin-top: 10px;
  margin-bottom: 10px;
}

.title-sec-inner .product-unit {
  display: flex;
  color: gray;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}

.product-unit .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 15px;
}

.product-unit span {
  font-size: 14px;
  color: var(--blue);
}

.delete-sec {
  display: flex;
}

.delete-sec .MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-sec .MuiBox-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 18px;
  cursor: pointer;
  color: gray;
  transition: font-size 0.3 linear, color 0.3s linear;
}

.delete-sec .MuiBox-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium:hover {
  font-size: 20px;
  color: red;
}

/* total payable section  */
.subtotal-sec {
  display: flex;
  justify-content: space-between;
}

.subtotal-sec .sub-total-text {
  color: #0d1136;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: right;
}

.payable-sec .total-sec {
  display: flex;
  justify-content: space-between;
}

.payable-sec .total-sec-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #b4138e;
}