.MuiPaper-root.MuiPaper-elevation.header-app-bar {
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 15%), 0px 4px 5px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 5%);
}

.MuiButtonBase-root.header-login-btn {
  color: #121d5f;
  font-weight: bold;
}

.MuiButtonBase-root.profile-icon {
  color: #121d5f;
}

.menu-icon.css-i4bv87-MuiSvgIcon-root {
  fill: #121d5f;
  transform: scale(1.1);
}

.cw-search-icon-property .MuiInputBase-root.MuiInputBase-colorPrimary {
  width: 100%;
}

.cw-search-icon-property .css-1iz4iln svg {
  fill: #121d5f;
}

.cw-search-icon-property
  .MuiInputBase-root.MuiInputBase-colorPrimary
  input::placeholder {
  color: #121d5f;
}

.cw-search-icon-property
  .MuiInputBase-root.MuiInputBase-colorPrimary
  input[type='text'] {
  color: #121d5f;
}

/* city section css  */
.find-ur-city {
  padding: 0 0.5em;
  align-items: center;
}
.find-ur-city .MuiSvgIcon-root {
  color: #ac168e;
}

.city-name {
  cursor: pointer;
  color: #af148e;
  line-height: 1 !important;
}

.change-city{
  cursor: pointer;
  color: #af148e;
  font-size: 13px !important;
  line-height: 1 !important;
}

@media screen and (max-width: 599px) {
  .cw-search-icon-property.top-search {
    display: none;
  }
}

.cw-search-icon-property.bottom-search {
  width: 90%;
  margin-right: 0;
}

@media screen and (max-width: 599px) {
  .logo-property img {
    width: 100%;
    min-width: 115px;
  }
}
