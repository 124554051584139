.breadcrumbs-container {
  background-color: white;
  padding-left: 15px;
  .MuiBreadcrumbs-li {

    .home,
    .relevant-field {
      font-size: 20px;
      color: #2cad93ad;
      transition: color 0.2s linear;
      font-weight: 500;

      &:hover {
        color: $logo-color2;
      }
    }

    .current-item {
      font-size: 20px;
      color: $logo-color1;
      font-weight: 500;
    }
  }

  .MuiBreadcrumbs-separator {
    color: $logo-color1;
  }
}