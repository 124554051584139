:root {
    --blue: #009e7f;
    --place-order: #fff;
}

.view-cart-footer {
    display: none;
    align-items: center;
    justify-content: space-between;
    background: #009e7f;
    border-radius: 3px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    flex-direction: column;
}

.shipping-offer-text {
    background-color: #ac168e;
    width: 100%;
    text-align: center;
    padding: 0.6em 1em;
    color: #e3e0e2;
}

@media screen and (max-width: 580px) {
    .shipping-offer-text p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 500px) {
    .shipping-offer-text p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .shipping-offer-text p {
        font-size: 0.9rem;
    }
}

.amount-count-sec {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #d7dee5;
    padding: 0.5em 0;
}

.amount-count-sec p {
    color: #000000;
    font-size: 0.85em;
    letter-spacing: 1px;
}

.amount-count-sec .amount-common {
    display: flex;
    justify-content: space-between;
}

.cart-item-below {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cart-item-below-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0.5em 0.9em;
}

.home-btn-link .css-jcn4dz-MuiBadge-badge {
    top: 7px;
    background-color: #fff;
    color: #000;
    padding: 0 4px;
}

.cart-back-link-btn {
    color: #fff;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    display: flex;
}

.item-and-cost {
    background: #fff;
    color: #009e7f;
    padding: 0.2em 0.5em;
    border-radius: 16px;
    font-size: 0.9em !important;
    min-width: 60px;
    text-align: center;
}

.cart-back-link-btn span {
    font-size: 0.9em;
    font-weight: 500;
}

@media screen and (max-width: 580px) {
    .view-cart-footer {
        display: flex;
    }
}

.view-cart-footer .home-btn-link,
.cart-back-link {
    text-decoration: none;
}

.view-cart-footer .place-order-common-btn {
    transition: color 0.2s linear;
    color: var(--place-order);
    border: 0;
    margin: 0.3em 0.2em;
    background: #006350;
    border-radius: 30px;
    padding: 3px 15px;
    font-size: 0.7em;
}

.view-cart-footer .place-order-common-btn:hover {
    color: #ddd;
    background: #006350;
}

@media screen and (max-width: 400px) {
    .view-cart-footer .place-order-common-btn {
        padding: 3px 9px;
    }
}