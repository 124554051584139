:root {
  --blue: #009e7f;
}

/* side drawer top section  */

.cart-top-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}
.total-item-count {
  color: var(--blue);
  margin-left: 1em !important;
  font-weight: bold;
}
.total-item-count .top-item-text {
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: var(--blue);
}
.cart-total-item-sec {
  display: flex;
  align-items: center;
}
.side-cart-main-section .cart-top-icon {
  color: var(--blue);
}

.close-sec .MuiButtonBase-root.close-sec-btn {
  background-color: #ffffff;
  border: 2px solid;
  border-color: #f1f1f1;
  border-radius: 100px;
  height: 38px;
  color: var(--blue);
  transition: background-color 0.3s linear, color 0.3s linear;
}

.close-sec .MuiButtonBase-root.close-sec-btn:hover {
  background-color: var(--blue);
  color: #fff;
}

/* side drawer bottom section  */
.bottom-section-main {
  position: fixed;
  bottom: 0;
  left: auto;
  right: 0;
  width: 385px;
  margin: 0.3em 0.5em;
  background: #ffffff;
  padding: 2em 0 0;
}
.bottom-section-main .shipping-offer {
  color: red;
  margin-bottom: 0.8em;
}
.check-out-btn {
}
.bottom-section-container {
}
.shipping-offer {
  text-align: center;
  margin: 1em 0;
}
.checkout-box {
  background: var(--blue);
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2em 0.2em;
}
.checkout-text {
  color: #fff;
  margin-left: 1em !important;
}

.checkout-btn {
  height: 38px;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #ffff !important;
  border-radius: 53px !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  color: var(--blue) !important;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5em 1em;
}

/* cart main content section  empty cart*/
.cart-content-sec {
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 67vh;
}

.MuiTypography-root.no-p-found-text {
  font-size: 1.3em;
  color: #bb5656;
  margin-top: 2em;
  font-weight: bold;
}

/* cart main content section cart-with-product*/
.cart-with-product{
  padding-bottom: 130px;
}
.btn-sec-grid {
  display: flex;
  align-items: center;
}
.cart-btn-sec {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  align-items: center;
  justify-content: center;
  margin: 6px 7px;
  border-radius: 20px;
  padding: 0em 0.3em;
  cursor: pointer;
  height: 100px;
}

.cart-btn-sec .cart-add-btn {
  font-size: 18px;
  margin-bottom: 0.8em;
  color: gray;
}

.cart-btn-sec .cart-input-btn {
  width: 15px;
  border: none;
  outline: none;
  background: none;
  font-weight: bold;
  color: #0d1136;
  text-align: center;
}

.cart-btn-sec input[type='number']::-webkit-inner-spin-button,
.cart-btn-sec input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-btn-sec .cart-remove-btn {
  font-size: 18px;
  margin-top: 0.8em;
  color: gray;
}

.product-img-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 400px) {
  .product-img-sec img {
    width: 50px;
  }
}
.title-sec {
}

.title-sec-inner {
  padding: 0.7em 0;
}
.title-sec-inner .product-title {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-weight: 600;
  color: #0d1136;
  margin-bottom: 0px;
  font-size: 0.95em;
  line-height: 1.2;
}
.title-sec-inner .product-price-sec {
  display: flex;
}
.product-price-sec .total-price {
  color: #0d1136;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2em;
}
.product-price-sec .unit-price {
  color: var(--blue);
  margin-top: 10px;
  margin-bottom: 10px;
}

.title-sec-inner .product-unit {
  display: flex;
  color: gray;
  font-size: 15px;
}

.product-unit .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 15px;
}

.delete-sec {
  display: flex;
  color: red;
}

.delete-sec .MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}
.delete-sec .MuiBox-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 20px;
  cursor: pointer;
  color: red;
  transition: font-size 0.3 linear, color 0.3s linear;
}

.delete-sec .MuiBox-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium:hover {
  font-size: 20px;
  color: red;
}



