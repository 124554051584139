.small-screen-login-sec {
  display: flex;
  justify-content: center;
  margin: 1.5em 0 1em;
  align-items: center;
}

.small-screen-login-sec .small-screen-login-btn {
  color: #ffffff;
  background-color: #009e7f;
}

.small-screen-login-sec .small-screen-login-btn:hover {
  background-color: #009e7eda;
}

.small-screen-user-sec {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 2.5em 0 0.5em 1em;
}

.small-screen-user-sec .user-info {
  margin-left: 2em;
}
.user-name {
  color: #ec008c;
}
.user--mobile {
  color: blue;
}
.small-screen-user-sec svg {
  fill: #2641e5 !important;
}

.order-info {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0em 1em 1em;
  cursor: pointer;
}

.order-info .my-order-text {
  margin-left: 1.8em;
  font-weight: bold;
}

.collapse-list-items .css-cveggr-MuiListItemIcon-root {
  min-width: 33px !important;
}

/* selected item desing  */
.MuiBox-root .list-item-main.active-item-bg {
  background: #f1f1f1fa;
}

.MuiBox-root .list-item-main .active-item {
  color: #009e7f;
  font-weight: bold !important;
}
.MuiBox-root .list-item-main .active-item span {
  font-weight: bold;
}

.collapse-list-items .active-item {
  color: #009e7f;
  font-weight: bold !important;
}
.collapse-list-items .active-item span {
  font-weight: bold;
}
.list-item-main .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  transform: rotate(270deg);
}

.list-item-main .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.active-item-icon {
  transform: rotate(180deg);
}

/* scrollbar design  */

::-webkit-scrollbar {
  width: 0.4em;
}

::-webkit-scrollbar-track {
  background: #f1f0f0;
  border-radius: 100vh;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #d1d1cb;
  border-radius: 100vh;
  border: 1px solid #f1f0f0;
  width: 0.25em;
  height: 0.25em;
}
